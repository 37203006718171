import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchDeskManagementList = createAsyncThunk("fetchDeskManagementList", async ({ page, filters, limit }) => {
	const keyword = filters && filters.keyword !== undefined ? `${filters.keyword}` : "";
	const company = filters && filters.company !== undefined ? `${filters.company}` : "";

	const token = localStorage.getItem("auth_token");
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};

	const response = await axiosHttp.get(
		`deskmanagement/admin/desk-list?keyword=${keyword}&companyId=${company}&page=${page}&limit=${limit}`,
		config
	);
	return response.data.data;
});

const deskManagementListSlice = createSlice({
	name: "deskManagementList",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchDeskManagementList.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(fetchDeskManagementList.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
			state.page = action.payload.current_page;
			state.total = action.payload.total;
		});
		builder.addCase(fetchDeskManagementList.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default deskManagementListSlice.reducer;
