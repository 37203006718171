import axiosHttp from "../../services/axiosHttp";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

// Async action
export const fetchCountStatus = createAsyncThunk("fetchCountStatus", async ({ identifier, id }, { rejectWithValue }) => {
	try {
		const token = localStorage.getItem("auth_token");

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		};

		let url = `status/count?module=${identifier}`;
		if (id) {
			url += `&id=${id}`;
		}

		const response = await axiosHttp.get(url, config);
		return response.data.data;
	} catch (error) {
		return rejectWithValue(error.response?.data?.message || error.message);
	}
});

// Slice
const countStatusSlice = createSlice({
	name: "countStatusSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},

	extraReducers: (builder) => {
		builder
			.addCase(fetchCountStatus.pending, (state) => {
				state.isLoading = true;
				state.error = null;
			})
			.addCase(fetchCountStatus.fulfilled, (state, action) => {
				state.isLoading = false;
				state.data = action.payload;
			})
			.addCase(fetchCountStatus.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.payload || "An unexpected error occurred.";
			});
	},
});

export default countStatusSlice.reducer;
