import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REWARD_BASE_URL } from "configs/AppConfig";
import axiosHttp from "services/axiosHttp";

export const poItemEdit = createAsyncThunk("poItemEdit", async (values, { rejectWithValue }) => {
	try {
		const {channel,po_id,item_id,data}  = values
		const response = await axiosHttp.put(`${REWARD_BASE_URL}channel/${channel}/po/${po_id}/item/${item_id}`, data);
		return response.data;
	} catch (err) {
		if (!err.response) throw err;
		return rejectWithValue(err.response.data);
	}
});

const RewardsPOItemEditSlice = createSlice({
	name: "RewardsPOItemEditSlice",
	initialState: {
		isLoading: false,
		isError: false,
		error: null,
		data: null,
	},
	reducers: {
		resetPOItemEditState: (state) => {
			state.isLoading = false;
			state.isError = false;
			state.error = null;
			state.data = null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(poItemEdit.pending, (state) => {
			state.isLoading = true;
			state.isError = false;
		});
		builder.addCase(poItemEdit.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.data = action.payload;
		});
		builder.addCase(poItemEdit.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.error = action.payload;
		});
	},
});

export const { resetPOItemEditState } = RewardsPOItemEditSlice.actions;
export default RewardsPOItemEditSlice.reducer;
