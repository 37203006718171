import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axiosHttp from "services/axiosHttp";

//comment create slice
export const deskStatusChange = createAsyncThunk("deskStatusChange", async (id) => {
	try {
		const response = await axiosHttp.patch(`deskmanagement/${id}/status`);
		message.success("Desk status has been changed successfully");
		return response.data;
	} catch (error) {
		message.error(error.response.data.message);
	}
});

const deskStatusChangeSlice = createSlice({
	name: "DeskStatusChangeSlice",
	initialState: {
		isLoading: false,
		data: {},
		error: null,
	},

	extraReducers: (builder) => {
		builder.addCase(deskStatusChange.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(deskStatusChange.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(deskStatusChange.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default deskStatusChangeSlice.reducer;
