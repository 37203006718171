import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REWARD_BASE_URL } from "configs/AppConfig";
import axiosHttp from "services/axiosHttp";

export const poAdd = createAsyncThunk("poAdd", async ({channel,values}, { rejectWithValue }) => {
	try {
		const response = await axiosHttp.post(`${REWARD_BASE_URL}channel/${channel}/po`, { ...values });
		return response.data;
	} catch (err) {
		if (!err.response) throw err;
		return rejectWithValue(err.response.data);
	}
});

const RewardsPOAddSlice = createSlice({
	name: "RewardsPOAddSlice",
	initialState: {
		isLoading: false,
		isError: false,
		error: null,
		data: null,
	},
	reducers: {
		resetPOAddState: (state) => {
			state.isLoading = false;
			state.isError = false;
			state.error = null;
			state.data = null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(poAdd.pending, (state) => {
			state.isLoading = true;
			state.isError = false;
		});
		builder.addCase(poAdd.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.data = action.payload;
		});
		builder.addCase(poAdd.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.error = action.payload;
		});
	},
});

export const { resetPOAddState } = RewardsPOAddSlice.actions;
export default RewardsPOAddSlice.reducer;
