import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REWARD_BASE_URL } from "configs/AppConfig";
import axiosHttp from "services/axiosHttp";

export const poItemRemove = createAsyncThunk("poItemRemove", async (values, { rejectWithValue }) => {
	try {
		const {channel,po_id,item_id}  = values
		const response = await axiosHttp.delete(`${REWARD_BASE_URL}channel/${channel}/po/${po_id}/item/${item_id}`);
		return response.data;
	} catch (err) {
		if (!err.response) throw err;
		return rejectWithValue(err.response.data);
	}
});

const RewardsPOItemRemoveSlice = createSlice({
	name: "RewardsPOItemRemoveSlice",
	initialState: {
		isLoading: false,
		isError: false,
		error: null,
		data: null,
	},
	reducers: {
		resetPOItemRemoveState: (state) => {
			state.isLoading = false;
			state.isError = false;
			state.error = null;
			state.data = null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(poItemRemove.pending, (state) => {
			state.isLoading = true;
			state.isError = false;
		});
		builder.addCase(poItemRemove.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.data = action.payload;
		});
		builder.addCase(poItemRemove.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.error = action.payload;
		});
	},
});

export const { resetPOItemRemoveState } = RewardsPOItemRemoveSlice.actions;
export default RewardsPOItemRemoveSlice.reducer;
