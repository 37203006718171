import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REWARD_BASE_URL } from "configs/AppConfig";
import axiosHttp from "services/axiosHttp";

export const poApproved = createAsyncThunk("poApproved", async (values, { rejectWithValue }) => {
	try {
		const {channel,po_id}  = values
		const response = await axiosHttp.put(`${REWARD_BASE_URL}channel/${channel}/po/${po_id}/approve`);
		return response.data;
	} catch (err) {
		if (!err.response) throw err;
		return rejectWithValue(err.response.data);
	}
});

const RewardsPOApprovedSlice = createSlice({
	name: "RewardsPOApprovedSlice",
	initialState: {
		isLoading: false,
		isError: false,
		error: null,
		data: null,
	},
	reducers: {
		resetPOApprovedState: (state) => {
			state.isLoading = false;
			state.isError = false;
			state.error = null;
			state.data = null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(poApproved.pending, (state) => {
			state.isLoading = true;
			state.isError = false;
		});
		builder.addCase(poApproved.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.data = action.payload;
		});
		builder.addCase(poApproved.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.error = action.payload;
		});
	},
});

export const { resetPOApprovedState } = RewardsPOApprovedSlice.actions;
export default RewardsPOApprovedSlice.reducer;
