import React from "react";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const ticketRoutes = [
	{
		key: "ticket-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/tickets/list`,
		component: React.lazy(() => import("views/app-views/tickets/pages/TicketList")),
	},
	{
		key: "ticket-admin-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/tickets/admin/list`,
		component: React.lazy(() => import("views/app-views/tickets/pages/AdminTicketList")),
	},
	{
		key: "ticket-create",
		exact: true,
		path: `${APP_PREFIX_PATH}/tickets/create/:back`,
		component: React.lazy(() => import("views/app-views/tickets/pages/TicketCreate")),
	},
	{
		key: "ticket-detail",
		exact: true,
		path: `${APP_PREFIX_PATH}/ticket/:id`,
		component: React.lazy(() => import("views/app-views/tickets/pages/TicketDetail")),
	},
	{
		key: "ticket-detail-admin",
		exact: true,
		path: `${APP_PREFIX_PATH}/ticket/admin/:id`,
		component: React.lazy(() => import("views/app-views/tickets/pages/TicketAdminDetail")),
	},
];
