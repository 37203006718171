import React from "react";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const adminModuleRoutes = [
	{
		key: "permission-manage",
		exact: true,
		path: `${APP_PREFIX_PATH}/admin/permission/manage`,
		component: React.lazy(() => import("views/admin-views/permission/pages/permssion-manage")),
	},
	{
		key: "desk-admin-manage",
		exact: true,
		path: `${APP_PREFIX_PATH}/admin/desk/manage`,
		component: React.lazy(() => import("views/admin-views/permission/pages/desk-admin-management")),
	},
	{
		key: "desk-admin-create",
		exact: true,
		path: `${APP_PREFIX_PATH}/admin/desk/create`,
		component: React.lazy(() => import("views/admin-views/permission/pages/desk-admin-create")),
	},
	{
		key: "desk-admin-update",
		exact: true,
		path: `${APP_PREFIX_PATH}/admin/desk/update/:id`,
		component: React.lazy(() => import("views/admin-views/permission/pages/desk-admin-update")),
	},
];
