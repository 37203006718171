import axiosHttp from "services/axiosHttp";
import { message } from "antd";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const helpDeskCreate = createAsyncThunk("helpDeskCreate", async (data, { rejectWithValue }) => {
	try {
		const response = await axiosHttp.post(`deskmanagement/create`, data);
		message.success("Desk has been created successfully!");
		return response.data;
	} catch (err) {
		message.error(err.response.data.message);
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

const helpDeskCreateSlice = createSlice({
	name: "helpDeskCreateSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},

	reducers: {
		resetHelpDeskCreateState: (state) => {
			state.isLoading = false;
			state.error = false;
			state.data = null;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(helpDeskCreate.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(helpDeskCreate.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(helpDeskCreate.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export const { resetHelpDeskCreateState } = helpDeskCreateSlice.actions;
export default helpDeskCreateSlice.reducer;
