import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchWFourList = createAsyncThunk("fetchWFourList", async ({ page, limit, filters }) => {
	const token = localStorage.getItem("auth_token");

	const keyword = filters?.keyword ? filters?.keyword : "";
	const made = filters?.made != null ? filters?.made : true;
	const deadline = filters?.deadline ? (filters?.deadline != "all" ? filters?.deadline : undefined) : "all";
	const status = filters?.status ? filters?.status : [7, 8, 9, 11];

	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};

	const response = await axiosHttp.post(
		`wfour/list?keyword=${keyword}&page=${page}&limit=${limit}&made=${made}&deadline=${deadline}`,
		{
			status: status.length > 0 ? (status.length == 1 && status[0] == "" ? [] : status) : [],
		},
		config
	);

	return response.data.data;
});

const wFourListSlice = createSlice({
	name: "wFourList",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchWFourList.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(fetchWFourList.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
			state.page = action.payload.page;
			state.total = action.payload.total;
		});
		builder.addCase(fetchWFourList.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default wFourListSlice.reducer;
