import axiosHttp from "services/axiosHttp";
import { message } from "antd";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const helpDeskUpdate = createAsyncThunk("helpDeskUpdate", async ({ id, data }, { rejectWithValue }) => {
	try {
		const response = await axiosHttp.patch(`deskmanagement/${id}/edit`, data);
		message.success("Desk has been updated successfully!");
		return response.data;
	} catch (err) {
		message.error(err.response.data.message);
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

const helpDeskUpdateSlice = createSlice({
	name: "helpDeskUpdateSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},

	extraReducers: (builder) => {
		builder.addCase(helpDeskUpdate.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(helpDeskUpdate.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(helpDeskUpdate.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default helpDeskUpdateSlice.reducer;
