import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REWARD_BASE_URL } from "configs/AppConfig";
import axiosHttp from "services/axiosHttp";

export const fetchItemSearch = createAsyncThunk("fetchItemSearch", async (values, { rejectWithValue }) => {
	try {
		const { channel, ...paramsWithoutChannel } = values;
		const params = new URLSearchParams(paramsWithoutChannel);
		const response = await axiosHttp.get(`${REWARD_BASE_URL}channel/${values.channel}/po-item-search?${ params }`);
		return response.data;
	} catch (err) {
		if (!err.response) throw err;
		return rejectWithValue(err.response.data);
	}
});

const RewardsItemSearchSlice = createSlice({
	name: "RewardsItemSearchSlice",
	initialState: {
		isLoading: false,
		isError: false,
		error: null,
		data: null,
	},
	reducers: {
		resetRewardsItemSearchSlice: (state) => {
			state.isLoading = false;
			state.isError = false;
			state.error = null;
			state.data = null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchItemSearch.pending, (state) => {
			state.isLoading = true;
			state.isError = false;
		});
		builder.addCase(fetchItemSearch.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.data = action.payload;
		});
		builder.addCase(fetchItemSearch.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.error = action.payload;
		});
	},
});

export const { resetRewardsItemSearchSlice } = RewardsItemSearchSlice.actions;
export default RewardsItemSearchSlice.reducer;
