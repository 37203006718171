import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REWARD_BASE_URL } from "configs/AppConfig";
import axiosHttp from "services/axiosHttp";

export const poReject = createAsyncThunk("poReject", async (values, { rejectWithValue }) => {
	try {
		const {channel,po_id}  = values
		const response = await axiosHttp.put(`${REWARD_BASE_URL}channel/${channel}/po/${po_id}/reject`);
		return response.data;
	} catch (err) {
		if (!err.response) throw err;
		return rejectWithValue(err.response.data);
	}
});

const RewardspoRejectSlice = createSlice({
	name: "RewardspoRejectSlice",
	initialState: {
		isLoading: false,
		isError: false,
		error: null,
		data: null,
	},
	reducers: {
		resetpoRejectState: (state) => {
			state.isLoading = false;
			state.isError = false;
			state.error = null;
			state.data = null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(poReject.pending, (state) => {
			state.isLoading = true;
			state.isError = false;
		});
		builder.addCase(poReject.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.data = action.payload;
		});
		builder.addCase(poReject.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.error = action.payload;
		});
	},
});

export const { resetpoRejectState } = RewardspoRejectSlice.actions;
export default RewardspoRejectSlice.reducer;
