import axiosHttp from "services/axiosHttp";
import { REWARD_BASE_URL } from "configs/AppConfig";
import moment from "moment";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchRewardOrderList = createAsyncThunk("fetchRewardOrderList", async ({ filters, page, limit }, { rejectWithValue }) => {
	let start_date = "";
	let end_date = "";

	if (filters?.range?.length === 2) {
		start_date = moment(filters.range[0].$d).format("YYYY-MM-DD");
		end_date = moment(filters.range[1].$d).format("YYYY-MM-DD");
	}

	const params = new URLSearchParams({
		page: page,
		limit: limit,
		keyword: filters?.keyword ?? "",
		status: filters?.status_id ?? "",
		user_type: filters?.user_type ?? "",
		payment_status: filters?.payment_status ?? "",
		channel: filters?.channel_uuid,
		date_from: start_date,
		date_to: end_date,
		payment_method: filters?.payment_method ?? "",
		verified: filters?.verified ?? "",
	});

	try {
		const response = await axiosHttp.get(`${REWARD_BASE_URL}orders?${params}`);
		return response.data.data;
	} catch (error) {
		return rejectWithValue(error.response?.data?.message || "Something went wrong");
	}
});

const RewardOrderListSlice = createSlice({
	name: "RewardOrderListSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchRewardOrderList.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchRewardOrderList.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
			state.page = action.payload.currentPage;
			state.total = action.payload.totalOrdersCount;
		});

		builder.addCase(fetchRewardOrderList.rejected, (state, action) => {
			state.isLoading = false;

			if (action.error.message) {
				state.error = action.error.message;
			} else {
				state.error = "Unknown error occurred";
			}
		});
	},
});

export default RewardOrderListSlice.reducer;
