import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchHelpDeskDetail = createAsyncThunk("fetchHelpDeskDetail", async (id) => {
	const response = await axiosHttp.get(`deskmanagement/${id}/detail`);
	return response.data.data;
});

const deskAdminDetailSlice = createSlice({
	name: "deskAdminDetailSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},
	extraReducers: (builder) => {
		builder.addCase(fetchHelpDeskDetail.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(fetchHelpDeskDetail.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(fetchHelpDeskDetail.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default deskAdminDetailSlice.reducer;
