import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REWARD_BASE_URL } from "configs/AppConfig";
import axiosHttp from "services/axiosHttp";

export const fetchGRNById = createAsyncThunk("fetchGRNById", async ({channel,id}, { rejectWithValue }) => {
	try {
		const response = await axiosHttp.get(`${REWARD_BASE_URL}channel/${channel}/grn/${id}`);
		return response.data;
	} catch (err) {
		if (!err.response) throw err;
		return rejectWithValue(err.response.data);
	}
});

const RewardsGRNAddSlice = createSlice({
	name: "RewardsGRNAddSlice",
	initialState: {
		isLoading: false,
		isError: false,
		error: null,
		data: null,
	},
	reducers: {
		resetfetchGRNByIdState: (state) => {
			state.isLoading = false;
			state.isError = false;
			state.error = null;
			state.data = null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchGRNById.pending, (state) => {
			state.isLoading = true;
			state.isError = false;
		});
		builder.addCase(fetchGRNById.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.data = action.payload;
		});
		builder.addCase(fetchGRNById.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.error = action.payload;
		});
	},
});

export const { resetfetchGRNByIdState } = RewardsGRNAddSlice.actions;
export default RewardsGRNAddSlice.reducer;
